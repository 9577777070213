import { Theme, BaseTheme } from "@uni-design-system/uni-core";
export const LightTheme: Theme = {
  ...BaseTheme,
  id: "LightTheme",
  name: "Light Theme",
  colors: {
    ...BaseTheme.colors,
    primary: "#006c4d",
    "on-primary": "#ffffff",
    "primary-container": "#86f8c8",
    "on-primary-container": "#002115",
    secondary: "#4c6358",
    "on-secondary": "#ffffff",
    "secondary-container": "#cfe9da",
    "on-secondary-container": "#092017",
    tertiary: "#3e6374",
    "on-tertiary": "#ffffff",
    "tertiary-container": "#c1e8fc",
    "on-tertiary-container": "#001f2a",
    error: "#ba1a1a",
    "on-error": "#ffffff",
    "error-container": "#ffdad6",
    "on-error-container": "#410002",
    background: "#fbfdf9",
    "on-background": "#191c1a",
    surface: "hsla(0,0%,100%,0.8)",
    "on-surface": "#191c1a",
    "surface-variant": "#dbe5de",
    "on-surface-variant": "#404944",
    outline: "#707973",
  },
};
