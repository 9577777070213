import { Theme, BaseTheme } from "@uni-design-system/uni-core";
export const DarkTheme: Theme = {
  ...BaseTheme,
  id: "DarkTheme",
  name: "Dark Theme",
  colors: {
    ...BaseTheme.colors,
    primary: "#69dbad",
    "on-primary": "#003827",
    "primary-container": "#005139",
    "on-primary-container": "#86f8c8",
    secondary: "#b3ccbe",
    "on-secondary": "#1f352b",
    "secondary-container": "#354b41",
    "on-secondary-container": "#cfe9da",
    tertiary: "#a6ccdf",
    "on-tertiary": "#083544",
    "tertiary-container": "#254b5b",
    "on-tertiary-container": "#c1e8fc",
    error: "#ffb4ab",
    "on-error": "#690005",
    "error-container": "#93000a",
    "on-error-container": "#ffdad6",
    background: "#191c1a",
    "on-background": "#e1e3df",
    surface: "hsla(247, 5%, 12%, 0.80)",
    "on-surface": "#e1e3df",
    "surface-variant": "#404944",
    "on-surface-variant": "#bfc9c2",
    outline: "#8a938d",
  },
};
